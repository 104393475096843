<template>
    <div>
        ทั่วไป
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>